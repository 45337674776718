<template>

  <router-view />
</template>

<script>

export default {
  name: 'App',
  
 
}
</script>
<style >

  @import '/assets/css/style.css?v=31';
  @import '/assets/css/mahdi.css?v=2113232323234312223231';
  @import '/assets/css/fonts.css?v432123232241';
  @import '/assets/css/sultan.css?v=3123'; 
  @import '/assets/css/dark.css?v=3121'; 



</style>
<style >
/* // @import "./assets/css/app"; */
* {
  font-family: 'cairo_reg', sans-serif;
}
html {
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #313942;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b0bd52;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a5a9c2;
}

.donate-btn {
    color: #fff !important
}

.about-btn
{
   color: #fff !important
}

.v-application ul, .v-application ol
{
  padding-left:0px !important;
}
</style>
